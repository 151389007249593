/* src/components/IntegralProcess.css */

.integral-process-section {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 60px 20px;
    font-family: 'Fig';
  }
  
  .process-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  /* Left side with steps */
  .steps-side {
    flex: 1;
    margin-right: 40px;
  }
  
  .step-card {
    background-color: #2b2b2b;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  
  .step-card:hover {
    background-color: #333333;
  }
  
  .step-card h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .step-card p {
    font-size: 0.9rem;
    color: #ffffff;
  }
  
  .step-number {
    position: absolute;
    right: 14px;
    bottom: 15px;
    background-color: #FFC700;
    padding: 5px 10px;
    font-size: 0.85rem;
    color: rgb(0, 0, 0);
    border-radius: 1px;
  }
  
  /* Right side with title, description, and button */
  .content-side {
    flex: 1;
    text-align: left;
  }
  
  .content-side h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .content-side h2 span {
    color: #FFC700; /* Highlight the word "Integral" */
  }
  
  .content-side p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .cta-button {
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: 2px solid #FFC700;
    border-radius: 5px;
    transition: all 0.3s ease;
    background-color: #1e1e1e;
  }
  
  .cta-button:hover {
    background-color: #FFC700;
    color: #000000;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 1024px) {
    .process-container {
      flex-direction: column;
    }
  
    .steps-side {
      margin-right: 0;
      margin-bottom: 30px;
    }
  
    .content-side {
      text-align: center;
    }
  
    .cta-button {
      display: inline-block;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .content-side h2 {
      font-size: 2rem;
    }
  
    .step-card h3 {
      font-size: 1.2rem;
    }
  
    .step-card p {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 480px) {
    .content-side h2 {
      font-size: 1.8rem;
    }
  
    .step-card h3 {
      font-size: 1rem;
    }
  
    .step-card p {
      font-size: 0.8rem;
    }
  
    .cta-button {
      font-size: 0.9rem;
    }
  }
  