/* src/components/OurWorks.css */

.our-works {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 160px 20px;
    overflow: hidden;
    background-color: transparent;
  }
  
  .background-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  .background-orange {
    position: absolute;
    background-color: #FFC700;
    width: 350px;
    height: 350px;
    z-index: 1;
  }
  
  /* Position the orange boxes */
  .background-orange-left {
    top: 30%;
    left: 22%;
  }
  
  .background-orange-top-right {
    top: 10%;
    right: 20%;
  }
  
  .image-container {
    position: absolute;
    width: 60%;
    height: 70%;
    z-index: 1;
    overflow: hidden;
  }
  
  .work-image {
    width: 60%;
    height: 100%;
    object-fit: cover;
    position: relative;
    left: 50%; /* Center image horizontally */
    transform: translateX(-50%);
  }
  
  .text-box {
    position: relative;
    right: 10%;
    top: 100px;
    background-color: #2b2b2b;
    padding: 40px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  
  .text-box h2 {
    font-size: 2rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .text-box p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .learn-more {
    display: inline-block;
    background-color: #FFC700;
    color: #000000;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .learn-more:hover {
    background-color: #e06929;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .background-orange {
      width: 250px;
      height: 250px;
    }
  
    .background-orange-left {
      top: 40%;
      left: 10%;
    }
  
    .background-orange-top-right {
      top: 5%;
      right: 10%;
    }
  
    .image-container {
      width: 80%;
      height: 50%;
    }
  
    .work-image {
      width: 70%;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .text-box {
      max-width: 40%;
      padding: 20px;
      top: 50px;
    }
  
    .text-box h2 {
      font-size: 1.5rem;
    }
  
    .text-box p {
      font-size: 0.9rem;
    }
  
    .learn-more {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  
  /* Extra-small screens */
  @media (max-width: 480px) {
    .background-orange {
      width: 180px;
      height: 180px;
    }
  
    .background-orange-left {
      top: 50%;
      left: 5%;
    }
  
    .background-orange-top-right {
      top: 10%;
      right: 5%;
    }
  
    .text-box {
      max-width: 60%;
      padding: 15px;
      right: 10%;
    }
  
    .text-box h2 {
      font-size: 1.3rem;
    }
  
    .text-box p {
      font-size: 0.8rem;
    }
  
    .learn-more {
      padding: 6px 12px;
      font-size: 0.8rem;
    }

    .work-image {
        width: 70%;
        height: 100%;
        top: -40%;
      }
  }
  