/* Main About Us Section */
.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Fig', sans-serif;
}

/* Container for Content */
.about-us-container {
  max-width: 900px;
  width: 100%;
  padding: 20px;
}

/* Title Styling */
.about-us-container h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #FFC700;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

/* Decorative Underline for Title */
.about-us-container h1::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #FFC700;
  margin: 10px auto;
}

/* Subtitle */
.about-us-container h2 {
  font-size: 1.8rem;
  color: #e0e0e0;
  margin: 10px 0 30px;
  font-weight: 500;
  line-height: 1.4;
}

/* Paragraph Styling */
.about-us-container p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #e0e0e0;
  max-width: 800px;
  margin: 0 auto 30px;
  font-weight: 300;
}

/* Read More Link Styling */
.about-us-container .read-more {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: 2px solid #FFC700;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.about-us-container .read-more:hover {
  background-color: #FFC700;
  color: #000000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-us-container h1 {
      font-size: 2.5rem;
  }
  
  .about-us-container h2 {
      font-size: 1.5rem;
  }
  
  .about-us-container p {
      font-size: 1rem;
  }
  
  .about-us-container .read-more {
      padding: 8px 16px;
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .about-us-container h1 {
      font-size: 2rem;
  }
  
  .about-us-container h2 {
      font-size: 1.3rem;
  }
  
  .about-us-container p {
      font-size: 0.9rem;
  }
}
