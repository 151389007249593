body{
    background-color: black;
}

.schedule-call-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    background-color: #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 100px;
    border-style: solid;
    border-color: white;
  }
  
  .schedule-call-page h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #ffffff;
  }
  
  .schedule-call-page p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #ffffff;
  }
  
  .calendly-widget {
    margin: 0 auto;
    width: 100%;
    min-height: 700px; /* Adjust height for the widget */
  }
  