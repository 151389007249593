/* Latest Posts Section */
.latest-posts-section {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 60px 20px;
  font-family: 'Fig', sans-serif;
  text-align: center;
}

/* Section Title */
.latest-posts-section h2 {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #FFC700;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.latest-posts-section h2::after {
  content: '';
  width: 80px;
  height: 3px;
  background-color: #FFC700;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

/* Posts Grid */
.posts-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
}

/* Individual Post Card */
.post-card {
  background-color: #2b2b2b;
  width: 100%;
  max-width: 620px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Card Image */
.post-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.post-card:hover img {
  transform: scale(1.1);
}

/* Post Content */
.post-content {
  padding: 20px;
}

.post-content h3 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #FFC700;
  margin: 0 0 10px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-content p {
  font-size: 1rem;
  color: #e0e0e0;
  margin-bottom: 15px;
  line-height: 1.6;
}

/* Link Styling */
.post-card-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.post-card-link:hover {
  color: #FFC700;
}

/* Responsive Styles */

/* Tablet Devices */
@media (max-width: 1024px) {
  .latest-posts-section h2 {
      font-size: 2.5rem;
  }

  .post-card img {
      height: 180px;
  }

  .post-content h3 {
      font-size: 1.4rem;
  }

  .post-content p {
      font-size: 0.95rem;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .posts-grid {
      grid-template-columns: 1fr;
  }

  .latest-posts-section h2 {
      font-size: 2.2rem;
  }

  .post-card img {
      height: 150px;
  }

  .post-content h3 {
      font-size: 1.3rem;
  }

  .post-content p {
      font-size: 0.9rem;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .latest-posts-section h2 {
      font-size: 2rem;
  }

  .post-card img {
      height: 120px;
  }

  .post-content h3 {
      font-size: 1.1rem;
  }

  .post-content p {
      font-size: 0.85rem;
  }
}
