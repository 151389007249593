/* src/components/Services.css */

.services-section {
  background-color: #1e1e1e;
  text-align: center;
  font-family: 'Fig';
}

/* Section Title */
.services-section h2 {
font-size: 3.5rem;
font-weight: bold;
color: #ffffff;
margin: 0 0 20px;
margin-top: 30px;
padding-bottom: 10px;
position: relative;
display: inline-block;
}

.services-section h2::after {
content: '';
width: 60px;
height: 3px;
background-color: #ffffff;
position: absolute;
bottom: 0;
left: 50%;
transform: translateX(-50%);
border-radius: 5px;
}

/* Section Description */
.services-description {
font-size: 1.2rem;
color: #ffffff;
max-width: 700px;
margin: 0px auto 10px;
line-height: 1.6;
font-weight: 400;
padding: 0 20px;
}

.services-cards {
display: flex;
justify-content: space-between;
max-width: 1300px;
width: 100%; /* Ensures the container stretches across the full width */
margin: 0 auto; /* Centers the container horizontally */
margin-top: 20px;
gap: 30px;
}

.service-card {
background-color: #000000;
/*border: 1px solid #ffffff;*/
padding: 40px;
width: 30%;
transition: transform 0.3s ease-in-out;
}

/* First card - color */
.service-card:nth-child(1) {
background-color: #2b2b2b;
}

/* Second card - color */
.service-card:nth-child(2) {
background-color: #2b2b2b;
}

/* Third card - color */
.service-card:nth-child(3) {
background-color: #2b2b2b;
color: #ffffff;
}

.service-card h3 {
font-size: 1.5rem;
font-weight: 600;
margin-bottom: 10px;
text-transform: uppercase;
border-bottom: 2px solid #ffffff; /* Add underline */
padding-bottom: 15px; /* Space between title text and underline */
display: inline-block;
font-family: 'Fig Extra Bold';
}

.service-card p {
font-size: 1.2rem;
margin-bottom: 20px;
}

.service-card button {
background-color: #ffffff;
color: #000000;
padding: 10px 30px;
border: none;
cursor: pointer;
font-size: 1rem;
transition: background-color 0.3s ease;
}

.service-card button:hover {
background-color: #f2f2f2;
}

.service-card ul {
list-style-type: none; /* Remove default bullet points */
padding-left: 0; /* Remove default padding */
}

.service-card ul li {
margin-bottom: 10px; /* Add spacing between each item */
position: relative;
padding-left: 0; /* Remove padding space for the custom icon */
font-size: 1rem; /* Adjust as needed */
color: #e0e0e0; /* Lighter color for text */
}


/* Responsive styles for smaller screens */

@media (min-width: 769px) {
.services-image {
  display: block;
  max-width: 20%; /* Adjust width as needed */
  height: auto;
  float: right; /* Positions the image on the right */
  margin-left: 20px; /* Adds space between the image and the text */
}
}

@media (max-width: 768px) {
.services-cards {
  flex-direction: column;
  align-items: center;
}

.service-card {
  width: 80%;
}

.services-section h2 {
  font-size: 2.5rem;
}

.services-description {
  font-size: 1.1rem;
}
}

@media (max-width: 480px) {
.services-section h2 {
  font-size: 2rem;
  max-width: 350px;
}

.service-card h3 {
  font-size: 1.2rem;
}

.service-card p {
  font-size: 1rem;
}

.service-card button {
  padding: 8px 16px;
  font-size: 0.9rem;
}
}
