/* Base Styling */
.contact-page {
  margin: 0;
  padding: 50px 20px;
  font-family: 'Fig', sans-serif;
  color: #ffffff;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  box-sizing: border-box;
}

/* Contact Page Title */
.contact-page h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #FFC700;
}

/* Enquiry Text */
.enquiry-text {
  font-size: 1.4rem;
  margin-bottom: 30px;
  color: #ffffff;
}

/* Contact Info Section */
.contact-info {
  margin-bottom: 30px;
}

.contact-info p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.contact-info i {
  color: #FFC700;
  font-size: 1.5rem;
}

/* Form Styling */
.contact-form {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px; /* Ensures padding on both sides */
  box-sizing: border-box;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  background-color: #1e1e1e;
  border: 1px solid #333;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;
  transition: border 0.3s ease;
  box-sizing: border-box; /* Ensures padding doesn’t exceed width */
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #FFC700;
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Submit Button */
.submit-button {
  padding: 12px 30px;
  background-color: #FFC700;
  color: #000000;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.submit-button:hover {
  background-color: #FFD633;
}

/* Success/Error Message */
.contact-page form p {
  color: #32CD32;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-page h1 {
      font-size: 2.5rem;
  }

  .enquiry-text {
      font-size: 1.2rem;
  }

  .contact-info p {
      font-size: 1rem;
  }

  .contact-form {
      padding: 0 15px; /* Adjusted for smaller screens */
  }

  .submit-button {
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-page {
      padding: 20px 10px;
  }

  .contact-page h1 {
      font-size: 2rem;
  }

  .enquiry-text {
      font-size: 1.1rem;
  }

  .form-group input,
  .form-group textarea {
      font-size: 0.9rem;
  }

  .contact-form {
      padding: 0 10px; /* Further reduced padding on mobile */
  }

  .submit-button {
      font-size: 0.9rem;
      padding: 10px 20px;
  }
}

