/* src/components/Homepage.css */

/* Base styles for larger screens (desktops) */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #000000;
  }
  
.homepage {
    background-color: #1e1e1e;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .homepage-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  nav ul li {
    cursor: pointer;
  }
  
  .homepage-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .welcome-text h1 {
    font-size: 6rem;
    font-weight: bold;
    line-height: 0.8;
    font-family: 'Fig';
    position: relative;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    animation: slideInRight 1s ease-out forwards; /* Slide in from the right on load */
}
  
  .welcome-text h1:hover {
    transform: translateX(10px); /* Move text 10px to the right on hover */
  }
  
  

  .small-text {
    font-size: 1.5rem; /* Adjust size as needed */
    color: #ffffff; /* Adjust color for 'To Target' */
  }
  
  .highlight-text {
    font-size: 1.5rem; /* Adjust size as needed */
    color: #FFC700; /* Choose a highlight color for 'BluPrint' */
  }

  /* Keyframes for the animation */
@keyframes slideInRight {
  0% {
      transform: translateX(-100%); /* Start from outside the left of the viewport */
      opacity: 0; /* Optional: fade in effect */
  }
  100% {
      transform: translateX(0); /* End at the original position */
      opacity: 1; /* End fully visible */
  }
}
  
  .image-section {
    text-align: right;
    font-family: 'Fig Extra Bold';
    position: relative;
    display: inline-block;
    margin-top: 70px;
    z-index: 1;
  }
  
  .image-section img {
     max-width: 350px;
     border-style: solid;
     border-width: 15px;
     border-color: #FFC700;
     border-right: #FFC700;
     border-bottom-width: 0px;
     transition: border-width 0.3s ease;
  }

  .image-section img:hover {
    border-width: 25px; /* Increase border width on hover */
    border-bottom-width: 0; /* Keep bottom border at 0 */
  }

  /* Text box beside the image, initially hidden */
.hover-text-box {
  position: absolute;
  top: 0%; 
  left: -10%; 
  transform: translateY(-50%); /* Center it vertically */
  padding: 20px;
  background-color: #FFC700 ;
  color: #000000;
  width: 200px;
  opacity: 1; /* Hide initially */
  visibility: hidden; /* Ensure it's not interactable when hidden */
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
  z-index: 3;
}

/* Show the text box when hovering over the image */
.image-section:hover .hover-text-box {
  opacity: 1;
  visibility: visible;
}
  
  .image-section p {
    margin-top: 10px;
  }
  
  .explore {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: relative;
    left: 10px;
    top: -450px;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 0;
  }

  .explore a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color to ensure it's consistent */
  }

  .explore-button {
    position: relative;
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-family: Arial, sans-serif;
  }
  
  .explore-button:hover .arrow {
    animation: moveArrow 0.5s infinite;
  }
  
  .arrow {
    display: block;
    transition: transform 0.2s;
    font-size: 1.5rem;
    position: relative;
    left: 30px;
    top: 60px;
  }
  
  

  @keyframes moveArrow {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }


  /******************************************* CSS for sliding in from the left **********************/
  .slide-in-left {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease-out, opacity 1s ease-out;
  }
  
  .slide-in-left.show {
    opacity: 1;
    transform: translateX(0);
  }

  /* Slide in from bottom */
.slide-in-bottom {
  opacity: 0;
  transform: translateY(100%); /* Start from below */
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.slide-in-bottom.show {
  opacity: 1;
  transform: translateY(0); /* Slide up to original position */
}
  /********************************************************************************************/
  
  /* Responsive styles for screens smaller than 768px */

  @media (max-width: 768px) {
    .homepage-header {
      flex-direction: column;
      align-items: center;
    }
  
    nav ul {
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  
    .homepage-content {
      flex-direction: column;
      text-align: center;
    }
  
    .welcome-text h1 {
      font-size: 3rem;
    }
  
    .image-section {
      text-align: center;
      margin-top: 20px;
    }
  
    .image-section img {
      max-width: 80%;
    }
  
    .explore {
      font-size: 1rem;
      left: 5px;
      top: -200px;
    }

    
  }



  @media (min-width: 769px) {

    .welcome-text h1 {
        position: relative;
        top: 300px;
        font-size: 6rem;
        font-weight: bold;
      }
  }
  
  /* Responsive styles for screens smaller than 480px (mobile phones) */
  @media (max-width: 480px) {
    .homepage-header {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  
    nav ul {
      flex-direction: row;
      gap: 10px;
      justify-content: center;
    }
  
    .welcome-text h1 {
      font-size: 2.5rem;
    }
  
    .image-section img {
      max-width: 90%;
    }
  
    .explore {
      font-size: 0.9rem;
      left: 3px;
      top: -320px;
    }

    .arrow {
      left: 28px;
      top: 60px;
    }
    
  }
  