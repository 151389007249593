/* Default navbar styling */
.navbar {
  background-color: black;
  color: white;
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease; /* Smooth transition for the shrinking effect */
  font-family: 'Fig Extra Bold';
  overflow: hidden;
}

/* Styling when scrolled */
.navbar.navbar-scrolled {
  background-color: black;
  width: 70%; /* Adjust the width when scrolled down */
  top: 10px; /* Move navbar slightly down */
  padding: 3px 15px; /* Reduce padding */
  border-radius: 8px; /* Optional: add rounded corners */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  background-color: transparent;
  max-height: 35px;
  width: auto;
  max-width: 100%;
}

.nav-links ul {
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.nav-links ul li {
  display: inline-block;
}

.nav-links ul li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: background-color 1s ease;
  background-color: transparent;
  border-radius: 8px;
  padding: 5px;
}

.nav-links ul li a:hover {
  color: #FFC700;
  background-color: #FFC700;
  padding: 5px;
  color: #000000;
}

.navbar-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-icons i {
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
}

.navbar-icons .get-in-touch {
  display: inline-block;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: 2px solid #FFC700;
    border-radius: 5px;
    transition: all 0.3s ease;
    background-color: transparent;
}

.navbar-icons .get-in-touch:hover {
  background-color: #FFC700;
  color: #000000;
}

/* Responsive Styles */
.hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (min-width: 1071px) {
  .nav-links ul {
    margin-left: 150px;
  }
}

@media (max-width: 1070px) {
  .nav-links ul {
    display: none;
    gap: 10px;
  }

  .nav-links.open ul {
    display: flex;
  }

  .hamburger {
    display: block;
    color: white;
  }

  .navbar-icons {
    display: none;
  }

  .get-in-touch {
    display: none;
  }
}

@media (max-width: 480px) {
  .navbar-icons i {
    font-size: 1rem;
  }
}
