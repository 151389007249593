/* About Page Styles */
.about-page {
    padding: 50px;
    background-color: #1e1e1e ; /* Dark blue background */
    font-family: Arial, sans-serif;
    color: #f6f6f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
}

/* Container for content */
.about-container {
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 1200px;
    width: 100%;
    background-color: #2b2b2b; /* Bright yellow background */
    padding: 40px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    position: relative;
    margin-top: 100px;
}

/* Image Section */
.about-image {
    position: relative;
    width: 45%;
}

.about-image img {
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Decorative Circle */
.decorative-circle {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #FFC700;
    top: -25px;
    left: -25px;
    z-index: 2;
}

/* Content Section */
.about-content {
    width: 55%;
    color: #f3f3f3;
}

.about-content h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff; /* Dark blue */
    margin-bottom: 20px;
}

.about-content p {
    font-size: 1.4rem;
    color: #ffffff;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Social Media Footer */
.about-footer {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.about-footer a {
    color: #ffffff; /* Match yellow background */
    font-size: 1.5rem;
    transition: color 0.3s;
}

.about-footer a:hover {
    color: #FFC700; /* Slightly different color on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30px;
    }

    .about-image, .about-content {
        width: 100%;
    }

    .about-content h1 {
        font-size: 2.5rem;
    }

    .about-content p {
        font-size: 1rem;
    }

    .decorative-circle {
        width: 80px;
        height: 80px;
        top: -15px;
        left: -15px;
    }
}
