/* Footer Styles */
.footer {
    background-color: transparent;
    color: #ffffff;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Fig';
  }
  
  .footer-container {
    background-color:#000000;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap; /* This ensures the items wrap on smaller screens */
  }
  
  .footer-item {
    flex: 1;
    margin: 20px;
  }
  
  .footer-item h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer-item p {
    margin: 0;
    font-size: 1rem;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons i {
    font-size: 1.5rem;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
  }
  
  .social-icons i:hover {
    color: #aaaaaa;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column; /* Stack items vertically on smaller screens */
      text-align: center;
    }
  
    .footer-item {
      margin: 10px 0;
    }
  }
  
  @media (max-width: 480px) {
    .footer-item h3 {
      font-size: 1rem;
    }
  
    .footer-item p {
      font-size: 0.9rem;
    }
  
    .social-icons i {
      font-size: 1.3rem;
    }
  }
  