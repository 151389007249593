

/* src/App.css or global styles */
.App {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Ensure content can be scrolled */
  overflow: auto; /* Allow the body to scroll */
}

